import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { Operation } from '../../../../../../api/models/operations/operation.model';
import { OperationsStore } from '../../stores/operations.store';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationFieldsList } from '../OperationFieldsList';
import { Dropdown } from '../../../../../shared/components/Dropdown';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import {
  Content,
  IconWrapper,
  Item,
  Label as ToolLabel,
} from '../../../../../shared/components/ToolTip/style';
import { ReactComponent as BinIcon } from '../../../../../shared/static/bin_red.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/static/edit.svg';
import { Colors } from '../../../../../shared/constans/colors';

import { OperationItemWrapper, OperationName, OperationDate, OperationMenu } from './style';

type Props = {
  operation: Operation;
  isActive: boolean;
  onClick: (operationId: string) => void;
  onMenuClick: () => void;
};

export const OperationItem: FC<Props> = observer(
  ({ operation, isActive, onClick, onMenuClick }) => {
    const store = useStore(OperationsStore);
    const [showFields, setShowFields] = useState<boolean>(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
    const [deleteErrorModal, setDeleteErrorModal] = useState<boolean>(false);

    const handleSelectOperation = () => {
      store.selectedOperationFieldId = '';
      onClick(operation.id);
    };

    const handleUIOperationClick = () => {
      setShowFields(prev => {
        if (prev === true) return false;
        else return true;
      });
    };

    useEffect(() => {
      if (operation.id === store.selectedOperationId) setShowFields(true);
      else setShowFields(false);
    }, [store.OperationFieldsList]);

    return (
      <>
        <OperationItemWrapper
          isActive={isActive}
          onClick={() => {
            handleSelectOperation();
            handleUIOperationClick();
          }}
          data-test-id={'operation-item'}
        >
          <OperationName data-test-id={'operation-item-name'}>{operation.name}</OperationName>
          <OperationDate data-test-id={'operation-item-date'}>
            {moment(operation.startDate).format('D MMM')} -{' '}
            {moment(operation.endDate).format('D MMM')}
          </OperationDate>
          <OperationMenu>
            <Dropdown>
              {closeDropdown => (
                <Content>
                  <Item
                    data-test-id={'operation-item-menu'}
                    onClick={() => {
                      closeDropdown();
                      onMenuClick();
                    }}
                  >
                    <IconWrapper>
                      <EditIcon />
                    </IconWrapper>
                    <ToolLabel>Редактировать</ToolLabel>
                  </Item>
                  <Item
                    data-test-id={'operation-item-menu'}
                    onClick={() => {
                      setConfirmDeleteModal(true);
                      closeDropdown();
                    }}
                  >
                    <IconWrapper>
                      <BinIcon />
                    </IconWrapper>
                    <ToolLabel color={Colors.red}>Удалить</ToolLabel>
                  </Item>
                </Content>
              )}
            </Dropdown>
          </OperationMenu>
        </OperationItemWrapper>
        {confirmDeleteModal && (
          <DialogModal
            status={'warning'}
            title={'Вы уверены, что хотите удалить операцию?'}
            accept={{
              name: 'Удалить',
              color: 'secondary',
              handler: () => {
                store
                  .deleteOperation({ operationId: operation.id })
                  .then(result => setConfirmDeleteModal(false))
                  .catch(err => {
                    setConfirmDeleteModal(false);
                    setDeleteErrorModal(true);
                    console.error(err);
                  });
              },
            }}
            cancel={{
              name: 'Отменить',
              color: 'default',
              handler: () => setConfirmDeleteModal(false),
            }}
          />
        )}
        {deleteErrorModal && (
          <DialogModal
            status={'warning'}
            title={'Произошла ошибка при удалении'}
            accept={{
              name: 'Продолжить',
              color: 'primary',
              handler: () => {
                setDeleteErrorModal(false);
              },
            }}
          />
        )}
        {store.selectedOperationId === operation.id && showFields && (
          <OperationFieldsList fields={store.OperationFieldsList} />
        )}
      </>
    );
  }
);
